import React from 'react';
import { motion } from 'framer-motion'; // Animation library
import nexus from './assets/nexus.png';
import img1 from './assets/1.png';
import img2 from './assets/2.png';
import img3 from './assets/3.png';
import img4 from './assets/4.png';
import img5 from './assets/5.png';
import img6 from './assets/6.png';
import './App.css';

function App() {
  const telegramBotLink = 'https://t.me/NarekNexus_bot';

  const content = [
    { image: img1, text: 'Добро пожаловать в мир уникальных товаров с Nexus! Исследуйте наш магазин.' },
    { image: img2, text: 'Мы предлагаем товары высочайшего качества и удобный способ покупки.' },
    { image: img3, text: 'Присоединяйтесь к тысячам счастливых пользователей Nexus.' },
    { image: img4, text: 'Ваши транзакции безопасны благодаря продвинутым технологиям.' },
    { image: img5, text: 'Не упустите эксклюзивные скидки на премиальные товары!' },
    { image: img6, text: 'Сделайте каждую покупку уникальной с Nexus. Начните прямо сейчас!' },
  ];

  return (
    <div className="App">
      {/* Header */}
      <header className="header">
        <motion.img
          src={nexus}
          className="App-logo"
          alt="Логотип Nexus"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        />
        <motion.h1
          className="title"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          Nexus Shop
        </motion.h1>
        <motion.a
          href={telegramBotLink}
          className="telegram-link"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.8, ease: 'easeOut' }}
        >
          Перейти в Telegram-бот
        </motion.a>
      </header>

      {/* Main Content */}
      <div className="main">
        <div className="grid-container">
          {content.map((item, index) => (
            <motion.div
              className="grid-item"
              key={index}
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true, amount: 0.3 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <img src={item.image} alt={`Контент ${index + 1}`} className="grid-image" />
              <p className="grid-text">{item.text}</p>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Footer */}
      <motion.footer
        className="footer"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 1 }}
      >
        <p>© 2024 Nexus Shop. Все права защищены.</p>
      </motion.footer>
    </div>
  );
}

export default App;
